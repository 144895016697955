import "./PageNotFound.css"
const PageNotFound = () => {


    return (
        <div id="page-not-found-page">
            <h1>404 Page Not Found</h1>
            <img src="https://media.sweetwater.com/api/i/f-webp__ha-9ced74217ac8a73c__hmac-0476eb5d2ab94209b4b48c78cc2c64555b5fdf9f/cart/case.png" />
        </div>
    )
}
export default PageNotFound;
